import * as $ from 'jquery';
import '../assets/lightbox_0.4/js/jquery.lightbox-0.5.js';
import Hashes from 'jshashes';
import './facebook.js';

//-------------------------------
$(window).on('scroll', function () {
	let logo_height = $('#app--logo-div').outerHeight(true),
      nav_height = $('#app--main-menu').outerHeight(true);

	if ($(window).scrollTop() >= logo_height) {
		$('#app--main-menu').addClass('main-menu-fixed');
		$('#app--content').css('margin-top', (nav_height) + 'px');

	} else {
		$('#app--main-menu').removeClass('main-menu-fixed');
		$('#app--content').css('margin-top', '0');
	}
});

function EdytujOpis(id, file_name) {
  var Title, Desc;
	$('#id_OpiszZdjecie_Div').show();
	$('#id_FileName').val(file_name);
	var file_title = $('#id_Gallery_[0]' + id).title;
	if (file_title.indexOf(' | ') != -1) {
		file_title = file_title.split(' | ');
		Title = file_title[0];
		Desc = file_title[1];
	} else {
		Title = file_title;
		Desc = '';
	}
	$('#id_Title').val(Title);
	$('#id_Desc').val(Desc);
	//$('#id_Title')[0].value = file_title[0];
	//$('#id_Desc')[0].value = (file_title[1] == undefined ? '' : file_title[1]);
}

function EmailValidatie(email) {
  // Patter from PHP	     
  var Pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  // var Pattern = /^([_a-zA-Z0-9])+([a-zA-Z0-9\._-])*@([a-zA-Z0-9_])+([.a-zA-Z0-9_-])+.[a-zA-Z0-9]{2,3}$/
  // OLD var Pattern = /^([A-Za-z0-9_])+([a-zA-Z0-9\._\-])\@([a-zA-Z0-9_])+([\.a-zA-Z0-9_\-])+.[a-zA-Z0-9]{2,3}$/;
  if (!Pattern.test(email)) { return false; }
  return true;
} // EmailValidatie

tinyMCE.baseURL = './assets/tinymce';

//--------------------------------------------------------------------------
$(function() {

  $('[page-delete]').on('click', function() {
    let question = $(this).attr('page-delete');

    if (confirm(question)) {
      window.location.href = $(this).attr('href');
    }
  });

	$('[data-toggle]').on('click', function() {
		let target = $(this).data('toggle'),
				display = $(this).data('display'),
				$target = $(target);
		if ([true, false].includes(display)) {
			$target.toggle(display);
		} else {
			$target.toggle();
		}
	});

	$('[data-pass-hash]').on('submit', function() {
		let $form = $(this),
				name = $form.data('pass-hash'),
				$input = $form.find(`[name="${name}"]`).first(),
				password = $input.val(),
				$hash = $('<input type="hidden" name="p" />'),
				SHA512 = new Hashes.SHA512;
		$form.append($hash);
		$hash.val(SHA512.hex(password));
		$input.val('');
	});

  $('[add-tag]').on('click', function() {
    let TagName = $(this).attr('add-tag');
    let Tags_Raw;

    if ($('#id_Tags_Cloud_input')[0].value.length > 1) {
      Tags_Raw = $('#id_Tags_Cloud_input')[0].value.split(', ');
    } else {
      Tags_Raw = new Array();
    }
    let NewTag = (TagName ? TagName : $('#id_NewTag')[0].value);
    let NewCloud = new Array();
    let IsNew = true;

    if (NewTag.length > 1) {
      // -----------------------------------------------------------------
      for (let i = 0; i < Tags_Raw.length; i++) {
        NewCloud.push(`<a class="tag-link" remove-tag="${Tags_Raw[i]}">${Tags_Raw[i]}</a>`);
        if (Tags_Raw[i] == NewTag) { IsNew = false; }
      }
      if (IsNew) {
        Tags_Raw.push(NewTag);
        NewCloud.push(`<a class="tag-link" remove-tag="${NewTag}">${NewTag}</a>`);
      }
  
      $('#id_Tags_Cloud_input')[0].value = Tags_Raw.join(', ');
      $('#id_NewTag')[0].value = '';
      $('#id_Tags_Cloud')[0].innerHTML = NewCloud.join(', ') + '&nbsp;';
      // -----------------------------------------------------------------
    } // length
  });

  $(document).on('click', '[remove-tag]', function() {
    let tag = $(this).attr('remove-tag');
    let Tags_Raw = $('#id_Tags_Cloud_input')[0].value.split(', ');
    let tag_id;
    let NewCloud = new Array();
    for (let i = 0; i < Tags_Raw.length; i++) {
      if (Tags_Raw[i] != tag) {
        NewCloud.push(`<a class="tag-link" remove-tag="${Tags_Raw[i]}">${Tags_Raw[i]}</a>`);
      } else {
        tag_id = i;
      }
    }
    Tags_Raw.splice(tag_id, 1);
  
    $('#id_Tags_Cloud_input')[0].value = Tags_Raw.join(', ');
    $('#id_Tags_Cloud')[0].innerHTML = NewCloud.join(', ') + '&nbsp;';
  });

	// TinyMCE --------------------------------------------------------------------------
	tinyMCE.init({
		selector: ".wysiwygTextArea",
		theme: "modern",
		plugins: [
			"advlist autolink link image lists charmap print preview hr anchor pagebreak spellchecker",
			"searchreplace wordcount visualblocks visualchars code fullscreen insertdatetime media nonbreaking",
			"save table contextmenu directionality emoticons template paste textcolor responsivefilemanager colorpicker" // codemirror
		],
		content_css: "./assets/bundles/wysiwyg.min.css",
		toolbar: "insertfile undo redo | styleselect | bold italic underline forecolor | responsivefilemanager  | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image media emoticons | code",
		style_formats: [
			{ title: 'Header H1', block: 'h1' },
			{ title: 'Header H2', block: 'h2' },
			{ title: 'Header H3', block: 'h3' },
			{ title: 'Header H4', block: 'h4' },
			{ title: 'Header H5', block: 'h5' }
		],
		templates: [
			{ title: 'Werset', description: 'Werset', content: 'My content' }
		],
		image_advtab: true,
		forced_root_block: false,
		convert_urls: false,
		//remove_linebreaks : false,	//<-- working, isn't it set to false by default??
		//force_br_newlines : false,	//<-- not working sad
		force_p_newlines: true,	//<-- seems to be working fine
		apply_source_formatting: true,
		//convert_newlines_to_brs : false,
		// codemirror: {
		// 	indentOnInit: true, 	// Whether or not to indent code on init. 
		// 	path: 'CodeMirror', 	// Path to CodeMirror distribution
		// },
		external_filemanager_path: "./assets/filemanager/",
		filemanager_title: "Responsive File Manager",
		external_plugins: {
			filemanager: "../filemanager/plugin.min.js"
		}
	});

	// PAGE --------------------------------------------------------------------------
	$('.Hide').hide();

	$('.RevealNext').on('click', function () {
		$(this).parent().parent().find('.Hide').toggle();
	});

	$('.Close').on('click', function () { $(this).parent().hide(); });

	$('a[href="#app"]').on('click', function (event) {
		event.preventDefault();
		$("html, body").animate({ scrollTop: 0 }, 500, 'easeInOutQuart');
	});

	// This is used on Show-Post-By-ID page
	$('a.Go_To_Header').on('click', function () {
		$('html, body').animate({
			scrollTop: $("h3:contains('" + $(this).html() + "')").offset().top
		}, 500, 'easeInOutQuart');
	});

	// Comments page
	$('.Comment_Box h4 a').on('click', function () {
		$('#id_Thread').val($(this).attr('title'));
		$('#id_New_Comment').show();
	});

	$('article.Szkic > div.Wrapper').bind('Minimize', function () {
		$(this).data('FullHeight', $(this).height());
		$(this).css({ 'height': '20px' });
	})
  .trigger('Minimize').on('click', function () {
		if ($(this).height() < $(this).data('FullHeight')) {
			$(this).animate({ height: $(this).data('FullHeight') });
		} else {
			$(this).animate({ height: 20 });
		}
	});

	// LINKS --------------------------------------------------------------------------
	$('a.External, a.out').on('click', function () {
		window.open(this.href);
		return false;
	});

	// MENU_2 --------------------------------------
	$("#app--main-menu > ul > li > a").hover(
		function () {
			var W = $(this).outerWidth();
			$(this).next('ul').css({ 'min-width': W + 'px' });
		},
		function () { }
	);

	// UI --------------------------------------------------------------------------
	$('.Feedback_div a').on('click', function () {
		$(this).parent('.Feedback_div').toggleClass('Feedback_div_full');
	});
	
	$('.def_value').on('click', function () {
		if (this.value == $(this).data('def_value')) {
			this.value = '';
		}
	});

	// User Menu ----------------------------------------
	var SideMenu = '<div class="SideMenu">MENU</div>';
	$('#app--user-menu').append(SideMenu);

	$('#app--user-menu div').on('click', function () {
		if ($('#app--user-menu a').is(':visible')) {
			$('#app--user-menu a, #app--user-menu span, #app--user-menu .SideMenu').slideUp(600);
		} else {
			$('#app--user-menu a, #app--user-menu span, #app--user-menu .SideMenu').not(':visible').slideDown(300);
		}
	});

	// IMAGES ---------------------------------------------------------------------
	if ($.fn.lightBox) {
		$('.zoom').lightBox({
			// Configuration related to overlay
			overlayBgColor: 		'#000',		// (string) Background color to overlay; inform a hexadecimal value like: #RRGGBB. Where RR, GG, and BB are the hexadecimal values for the red, green, and blue values of the color.
			overlayOpacity:			0.8,		// (integer) Opacity value to overlay; inform: 0.X. Where X are number from 0 to 9
			// Configuration related to images
			imageLoading:			'./assets/images/lightbox-ico-loading.gif',		// (string) Path and the name of the loading icon
			imageBtnPrev:			'./assets/images/lightbox-prevlabel.gif',			// (string) Path and the name of the prev button image
			imageBtnNext:			'./assets/images/lightbox-nextlabel.gif',			// (string) Path and the name of the next button image
			imageBtnClose:		'./assets/images/lightbox-btn-close.gif',		// (string) Path and the name of the close btn
			imageBlank:				'./assets/images/lightbox-blank.gif',			// (string) Path and the name of a blank image (one pixel)
			// Configuration related to container image box
			containerBorderSize:	10,			// (integer) If you adjust the padding in the CSS for the container, #lightbox-container-image-box, you will need to update this value
			containerResizeSpeed:	400,		// (integer) Specify the resize duration of container image. These number are miliseconds. 400 is default.
			// Configuration related to texts in caption. For example: Image 2 of 8. You can alter either "Image" and "of" texts.
			txtImage:				'Obraz',	// (string) Specify text "Image"
			txtOf:					'z',		// (string) Specify text "of"
    });
	}

	// INPUTS - CONTACT FORM ---------------------------------------------------------------------
	$('.Input_Email').click(function () {
		if (this.value == $(this).data('info')) {
			this.value = '';
		}
	});

	$('.Input_Email').blur(function () {
		if (this.value == '') {
			this.value = $(this).data('info');
		}
	});

	$('.Input_Email').change(function () {
		if (EmailValidatie(this.value) == false) {
			var Feedback = $(this).data('info');
			$(this).closest('.Form_Mail').find('.Form_Feedback').html('<div class="j-alert j-alert-error">' + Feedback + '</div>');
		} else {
			$(this).closest('.Form_Mail').find('.Form_Feedback').html('&nbsp;');
		}
	});

	$('.Form_Mail').submit(function () {
		var Validate = $('input[name="Validate"]', this).val();
		var Content = $('textarea[name="Content"]', this).val();
		if (Validate.length < 4 || Content.length < 4) {
			var Feedback = $(this).data('fill');
			$(this).closest('.Form_Mail').find('.Form_Feedback').html('<div class="j-alert j-alert-error">' + Feedback + '</div>');
			return false;
		}
	});

	// INPUTS ---------------------------------------------------------------------
	// DatePicker
	$('.Input_Date').datepicker({
		firstDay: 1,
		dateFormat: 'dd-mm-yy',
		dayNamesMin: ['Ni', 'Po', 'Wt', 'Śr', 'Cz', 'Pt', 'So'],
		dayNames: ['Niedziela', 'Poniedzia�ek', 'Wtorek', 'Środa', 'Czwartek', 'Piątek', 'Sobota'],
		monthNames: ['Styczeń', 'Luty', 'Marzec', 'Kwiecień', 'Maj', 'Czerwiec', 'Lipiec', 'Sierpień', 'Wrzesień', 'Październik', 'Listopad', 'Grudzień']
	});

	//ColorPicker
	/*
	$(".ColorPicker_Input").ColorPicker({
		onSubmit: function(hsb, hex, rgb, el) {
			$(el).val(hex);
			$(el).ColorPickerHide();
		},
		onBeforeShow: function () {
			$(this).ColorPickerSetColor(this.value);
		}
	})
	.bind('keyup', function(){
		$(this).ColorPickerSetColor(this.value);
	});
	*/

	// Admin --------------------------------------------------------------------------
	// For Adm_EditMenu
	$('.admin-menu-more').hide();
	$('.admin-menu-show-more').on('click', function () {
		var ID = $(this).parents('form').find('input[name="id"]').val();
		if ($(`.MoreID_${ID}`).is(":visible")) {
			$(`.MoreID_${ID}`).slideUp(100);
			$(this).find('.fa').addClass('fa-caret-down').removeClass('fa-caret-up');
		} else {
			$(`.MoreID_${ID}`).not(":visible").slideDown(50);
			$(this).find('.fa').addClass('fa-caret-up').removeClass('fa-caret-down');
		}
	});

	// This is on Adm_EditPage and Adm_EditMenu
	$('.Sibling_Select').change(function () {
		var ID = $(this).data('target');
		$(this).parent().find(`input[name="${ID}"]`).val(this.value);
	});

	$('.Sibling_Select').not('.admin-input').selectmenu({
		change: function () {
			var ID = $(this).data('target');
			$(this).parent().find(`input[name="${ID}"]`).val(this.value);
		}
	});
	
	$('.FarSibling_Button').click(function () {
		var ID = $(this).data('target');
		var Value = $(this).data('value');
		$('#' + ID).val(Value);
	});

	$('.jqButton').not('.admin-input').button();
	$('.jqInput').button().off('mousedown').off('keydown');
	$('.jqSpinner').spinner();
	//$('.ui-spinner-input').css({'margin':'0px','border':'none'}); // removes double border
	$('.jqSelect').selectmenu();
	$('.jqButtonSet').buttonset();

	// OTHER --------------------------------------------------------------------------

	$('.aDonate').button().on('click', function (event) {
		event.preventDefault();
	});

});

